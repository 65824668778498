import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-inner-banner',
  templateUrl: './inner-banner.component.html',
  styleUrls: ['./inner-banner.component.scss'],
})
export class InnerBannerComponent {
  @ViewChild('tooltip') tooltip: any;

  @Input() parentData: any[] = [];
  @Input() title: string | undefined = '';
  @Input() brief: string | undefined = '';
  @Input() image: string | undefined =
    '../../../../assets/images/common/banner.webp';

  copy = () => {
    //do copy stuff
    this.tooltip.show();
    setTimeout(() => this.tooltip.hide(1500));
  };
  // ngOnInit(): void {}
}
