import { Component } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { Customers } from 'src/app/shared/viewModels/customers';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent {

  constructor(private getData: GetDataService) { }

  data: Customers[] | any = [];

  ngOnInit() {
    //Get Nav Data ..
    this.getData.getData(`${environment.apiBaseUrl}/api/v1/client`).subscribe(
      data => {
        this.data = data.data;
      },
      error => {
      }
    );
  }

  slideConfig = {
    "slidesToShow": 5,
    "slidesToScroll": 1,
    'autoplay': true,
    'autoplaySpeed': 3500,
    'dots': true,
    'infinite': true,
    'arrows': false,
    'responsive':
      [{
        'breakpoint': 1200,
        'settings':
        {
          'slidesToShow': 4,
          'slidesToScroll': 1,
        }
      },
      {
        'breakpoint': 1024,
        'settings':
        {
          'slidesToShow': 3,
          'slidesToScroll': 1,
        }
      },
      {
        'breakpoint': 600,
        'settings':
        {
          'slidesToShow': 2,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 360,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      },
      {
        'breakpoint': 0,
        'settings':
        {
          'slidesToShow': 1,
          'slidesToScroll': 1
        }
      }
      ]
  };
  afterChange(e:any) {
    
  }

  beforeChange(e:any) {
   
  }
}
