import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-common-card',
  templateUrl: './common-card.component.html',
  styleUrls: ['./common-card.component.scss'],
})
export class CommonCardComponent {
  @Input() title: string | undefined = 'Process Automation';
  @Input() image: string | undefined =
    '../../../../assets/images/common-card/image.webp';
  @Input() link: string | undefined = '/home';
  outY: any;
  inY: any;
  outX: any;
  inX: any;

  constructor(private router: Router) { }
  onMouseDown(event: any) {
    this.inX = event.clientX;
    this.inY = event.clientY;
  }
  onMouseUp(event: any) {
    this.outX = event.clientX;
    this.outY = event.clientY;
    if (this.inX == this.outX && this.inY == this.outY) {
      this.navigateToNewPath();
    }
  }
  navigateToNewPath() {
    this.router.navigate([this.link]).then(() => {
      window.location.reload();
    });
  }
}
