import { Component, EventEmitter, Output } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { Banner } from 'src/app/shared/viewModels/banner';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  constructor(private getData: GetDataService, private router: Router) { }

  @Output() dataEmitter: EventEmitter<any> = new EventEmitter<any>();
  data: Banner[] | any = [];

  ngOnInit() {
    //Get Nav Data from Api ..
    this.getData
      .getData(`${environment.apiBaseUrl}/api/v1/headerSlideItem`)
      .subscribe(
        (data) => {
          this.data = data.data;
          this.dataEmitter.emit(true);
        },
        (error) => {
          console.log(error);
        }
      );
  }
  navigateToNewPath(link: string) {
    this.router.navigate([link]).then(() => {
      window.location.reload();
    });
  }
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
    dots: true,
    infinite: true,
    arrows: false,
    useTransform: false,
    focusOnSelect: true,
    pauseOnHover: false
  };
}
