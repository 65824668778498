import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { TopCards } from 'src/app/shared/viewModels/top-cards';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  breadCrumb: any = [{ path: 'Products', routerLink: '/products' }];
  title: string = 'Products';
  brief: string =
    'Our digital products are tailored to every aspect of making your digital business succeed, targeting a seamless global solution. ';
  image: string = '../../../../assets/images/common-products/Products.webp';
  products: TopCards[] = []
  constructor(private _data: GetDataService, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this._data.getData(`${environment.apiBaseUrl}/api/v1/products`).subscribe(
      (data) => {

   data.data.map((item:any)=>{
   const p = item.imageUrl
   const test = p?.replace('mediamedia','media')
   item.imageUrl=test
   })
   this.products = data.data;  
        this.hideLoading();
        //Send page title to google analytics
        gtag('config', 'G-YGS8HSR5WZ', {
          page_title: 'Products Listing',
          page_path: window.location.pathname,
        });
      },

      (error) => {
        console.log(error);
      }
    );
  }

  //Hide Loading page
  hideLoading() {
    setTimeout(() => {
      this.loadingComponent.hideLoading();
      const body = this.el.nativeElement.ownerDocument.body;
      this.renderer.removeClass(body, 'loading-overflow');
    }, 500);
  }
}
