<div class="details-related details-section">
  <div class="container px-4">
    <ng-container *ngIf="type == 'service'; else elseBlock">
      <h2 class="main-title">{{ serviceTitle }}</h2>
      <!-- <p class="brief">{{ serviceBrief }}</p> -->
    </ng-container>
    <ng-template #elseBlock>
      <h2 class="main-title">{{ productTitle }}</h2>
      <!-- <p class="brief">{{ productBrief }}</p> -->
    </ng-template>
    <div class="related-holder">
      <ngx-slick-carousel
        class="related-carousel"
        [ngClass]="type == 'service' ? 'service-carousel' : 'product-carousel'"
        #slickModal="slick-carousel"
        [config]="type == 'service' ? slideConfigServ : slideConfigProd"
      >
        <div ngxSlickItem *ngFor="let card of related" class="slide h-100">
          <ng-container *ngIf="type == 'service'; else elseCards">
            <app-common-card
            class="w-100"
              [title]="card.name"
              [image]="card.imgUrl"
              style="width: 100%"
              [link]="'services/' + card?.urlName"
            />
          </ng-container>
          <ng-template #elseCards>
   <div class="d-flex flex-column w-100">
    <app-common-card-top-products
   
    [subTitle]="card?.name"
    [title]="card?.category"
    [brief]="card?.description"
    [link]="'products/' + card?.urlName"
  ></app-common-card-top-products>
   </div>
          </ng-template>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
