import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  data: any[] = [];
  tabIndex: string | null = '0';

  breadCrumb: any = [{ path: 'Services', routerLink: '/services' }];
  title: string = 'Services';
  brief: string =
    'Increase the speed of software delivery, increase the software quality and security, and reduce the cost of software development and operations. ';
  image: string = '../../../../assets/images/services/stars.webp';
  constructor(private getData: GetDataService, private route: ActivatedRoute, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    //Get id from URL path to select tab
    this.tabIndex = this.route.snapshot.paramMap.get('id');

    //Detect URL path to send new param if it changed in the same component
    this.route.paramMap.subscribe(() => {
      this.tabIndex = this.route.snapshot.paramMap.get('id');
    });

    //Fetch API
    this.getData.getData(`${environment.apiBaseUrl}/api/v1/services`).subscribe(
      (data) => {
        this.data = data.data;
        this.hideLoading();
       

        //Send page title to google analytics
        gtag('config', 'G-YGS8HSR5WZ', {
          page_title: 'Services Listing',
          page_path: window.location.pathname,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  //Hide Loading page
  hideLoading() {
    setTimeout(() => {
      this.loadingComponent.hideLoading();
      const body = this.el.nativeElement.ownerDocument.body;
      this.renderer.removeClass(body, 'loading-overflow');
    }, 500);
  }
}
