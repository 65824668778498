<div class="p-2">
  <h1 class="success-drivers">Success drivers</h1>
  <div class="d-flex justify-content-center">
    <!-- <p class="qeema-des">
      Lorem ipsum dolor sit amet consectetur. Sagittis libero ullamcorper
      sagittis ornare nam facilisis purus neque donec.
    </p> -->
  </div>
</div>

<div class="">
  <div class="row g-xl-2">
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\noun-rating-notes-4755976 1.webp"
              alt="Improve Customer Experience" title="Improve Customer Experience" />
          </div>
          <div>
            <h1 class="drivers-des sc">improve customer experience</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\noun-agile-204943.webp"
              alt="Agile Operation & Increased Flexibility" title="Agile Operation & Increased Flexibility" />
          </div>
          <div>
            <h1 class="drivers-des sc">
              agile Operation & increased flexibility
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\four.webp" alt="Enable Digital Transformation"
              title="Enable Digital Transformation" />
          </div>
          <div>
            <h1 class="drivers-des sc">Enable digital transformation</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\last.webp" alt="Faster Time To Market"
              title="Faster Time To Market" />
          </div>
          <div>
            <h1 class="drivers-des sc">faster time to market</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\noun-statistics-4524743 1.webp"
              alt="Improved Service Performance" title="Improved Service Performance" />
          </div>
          <div>
            <h1 class="drivers-des sc">Improved service performance</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\reduce.webp" alt="Reduced Business & Technological Risk"
              title="Reduced Business & Technological Risk" />
          </div>
          <div>
            <h1 class="drivers-des sc">
              Reduced business & technological risk
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\signal.webp" alt="Reduced / Rationalized Expenditures"
              title="Reduced / Rationalized Expenditures" />
          </div>
          <div>
            <h1 class="drivers-des sc">Reduced / Rationalized Expenditures​</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="column col-xxl-3 col-md-4 col-sm-6 col-12">
      <div class="grey-box h-100">
        <div class="white-box d-flex bg-white">
          <div>
            <img class="img" src="assets\images\about-us\privacy.webp" alt="Ensured Data / Information Security​"
              title="Ensured Data / Information Security​" />
          </div>
          <div>
            <h1 class="drivers-des sc">Ensured Data / Information Security​</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  @media (min-width: 0) {
    .column {
      /* height: 78px; */
      margin-bottom: 12px;
    }

    .success-drivers {
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 40.976px;
      /* 186.253% */
      text-transform: capitalize;
      margin-bottom: 33px;
    }

    .qeema-des {
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      width: 257px;
      margin-bottom: 33px;
    }

    .grey-box {
      display: flex;
      padding: 14px 0;
      align-items: center;
      background: #f6f6f6;
      width: 90%;
      margin: 0 auto;
      /* height: 77.6px; */
    }

    .sc {
      margin-top: 0;
      margin-bottom: 0;
    }

    .white-box {
      display: flex;
      height: 100%;
      padding: 11.899px 14.096px 11.899px 4.141px;
      align-items: center;
      gap: 8.281px;
      width: 90%;
      max-width: 90%;
    }
  }

  .img {
    width: 40px;
    /* height: 19.557px; */
    flex-shrink: 0;
  }

  .drivers-des {
    color: var(--black-2, #141414);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: capitalize;
  }

  @media (min-width: 576px) {
    .grey-box {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    .column {
      /* height: 105.6px; */
      margin-bottom: 12px;
    }

    .success-drivers {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 57.2px;
      /* 186.253% */
      text-transform: capitalize;
      margin-bottom: 46px;
    }

    .qeema-des {
      font-style: normal;
      letter-spacing: -0.16px;
      width: 50%;
      margin-bottom: 46px;
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.02em;
      text-align: center;
    }

    .grey-box {
      display: flex;
      /* width: 221.788px; */
      /* padding: 23.117px 23.118px 26.008px 0px; */
      /* height: 101px; */
      align-items: flex-start;
      gap: 20.631px;
      background: #f6f6f6;
    }

    .white-box {
      display: flex;
      /* width: 198.67px; */
      /* height: 59.24px; */
      padding: 13px 5.781px 14.453px 6.504px;
      /* justify-content: center; */
      align-items: center;
      gap: 11.556px;
    }

    .img {
      /* width: 33.232px; */
      /* height: 34.677px; */
      flex-shrink: 0;
    }

    .drivers-des {
      color: var(--black-2, #141414);
      font-size: 13.004px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.338px;
      /* 133.333% */
      text-transform: capitalize;
    }
  }

  /* laptops*/
  @media (min-width: 992px) {
    .column {
      /* height: 150px; */
      margin-bottom: 32px;
    }

    .success-drivers {
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 40.976px;
      /* 186.253% */
      text-transform: capitalize;
      margin-bottom: 72px;
    }

    .qeema-des {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.16px;
      width: 585px;
      margin-bottom: 72px;
    }

    .grey-box {
      display: flex;
      /* width: 307px; */
      /* height: 150px; */
      /* padding: 32px 32px 32px 0px; */
      align-items: center;
      background: #f6f6f6;
    }

    .white-box {
      display: flex;
      /* width: 275.67px; */
      /* height: 82px; */
      padding: 18px 36px 18px 12px;
      align-items: center;
      gap: 8px;
    }

    .img {
      /* width: 33.704px; */
      /* height: 37.788px; */
      flex-shrink: 0;
    }

    .drivers-des {
      color: var(--black-2, #141414);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 133.333% */
      text-transform: capitalize;
    }

    @media (min-width: 1200px) {
      .drivers-des {
        font-size: 18px;
      }

      .img {
        width: 46px;
      }

      .grey-box {
        width: 95%;
      }
    }
  }
</style>