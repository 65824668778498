<div
  class="details-image-diagram details-section"
  [style.background]="imageDiagramSection?.imageBackgroundColor"
>
  <div class="container">
    <img
      class="imgHolder"
      [src]="imageDiagramSection?.imgUrl"
      [alt]="imageDiagramSection?.imageCaption"
      [title]="imageDiagramSection?.imageCaption"
    />
    <p class="imgCaption">{{ imageDiagramSection?.imageCaption }}</p>
  </div>
 
</div>

<!--image 2-->
<div
  class="details-image-diagram details-section " style="padding: 0;"
  [style.background]="imageDiagramSection2?.imageBackgroundColor"
>
<div class="container second-img space-two-image"  *ngIf="imageDiagramSection2!=null">
  <img
    class="imgHolder"
    [src]="imageDiagramSection2?.imgUrl"
    [alt]="imageDiagramSection2?.imageCaption"
    [title]="imageDiagramSection2?.imageCaption"
  />
  <p class="imgCaption"[innerHTML]=" imageDiagramSection2?.imageCaption"></p>
</div>
 
</div>
