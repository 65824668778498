<div class="position-relative">
  <img class="img-fluid img" src="../../../../assets/images/about-us/main.webp" alt="Who We Are" title="Who We Are" />
  <div class="box">
    <div class="inner-box">
      <h1 class="title">Looking For Help?</h1>
      <h1 class="brief">
        <!-- Sales dolor sit amet consectetur. Sagittis libero ullamcorper sagittis
        ornare nam facilisis -->
      </h1>
      <button routerLink="/contact">Get in Touch</button>
    </div>
  </div>
</div>