import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { NewsCard } from 'src/app/shared/viewModels/news-card';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  image: string = '../../../assets/images/news/news_banner.webp';
  next: string = '../../../assets/images/news/next.webp';
  back: string = '../../../assets/images/news/back.webp';
  responsive: boolean = true;
  @Output()
  pageChange!: EventEmitter<number>;
  @Output()
  pageBoundsCorrection!: EventEmitter<number>;

  data: NewsCard[] = [];
  request = {
    first: 1,
    size: 9,
    
  };
  askForSection:
    | { title: string; description: string; link: string }
    | undefined;
  breadCrumb: any = [{ path: 'Latest News', routerLink: '/news ' }];
  pageTitle: string = 'Latest News';
  brief: string =
    'Stay ahead of the competition with our cutting-edge solutions and dedication to innovation. Follow us and discover what sets us apart from the rest.';
  currentPage = 1;
  totalItems!:number;
  maxSize = 5;
  jsonArray = this.data;
  constructor(private getData: GetDataService, private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
   this.pagination()
   
  }
  pagination(){
    this.getData.getDataPagination(this.request.first-1,this.request.size).subscribe(
      (data) => {
        this.data = data.content;
        
        this.totalItems=data.totalElements
        this.hideLoading();
        //Send page title to google analytics
        gtag('config', 'G-YGS8HSR5WZ', {
          page_title: 'News Listing',
          page_path: window.location.pathname,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }
 
  onTableDataChange(event:any){
    this.request.first  = event ;
 
    this.pagination();
  }
 
  //Hide Loading page
  hideLoading() {
    setTimeout(() => {
      this.loadingComponent.hideLoading();
      const body = this.el.nativeElement.ownerDocument.body;
      this.renderer.removeClass(body, 'loading-overflow');
    }, 500);
  }
}
