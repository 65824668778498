import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { ToastrService } from 'ngx-toastr';
import { LoadingPageComponent } from 'src/app/shared/components/loading-page/loading-page.component';
import { GetDataService } from 'src/app/shared/services/get-data.service';
import { environment } from 'src/environments/environment';

export interface ContactusData {
  body: string;
  email: string;
  fullName: string;
  jobTitle: string;
  phone: string;
  recaptcha: string;
}
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  @ViewChild('loadingComponent') loadingComponent!: LoadingPageComponent;
  submitted = false;
  thanksMessage: string = '';
  breadCrumb: any = [{ path: 'Get in Touch', routerLink: '/contact' }];
  title: string = 'Get in touch ';
  brief: string = 'We appreciate your interest in Qeema. ';
  image: string = '../../../../assets/images/Contactus/contactUs.webp';
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;

  preferredCountries: CountryISO[] = [CountryISO.Egypt, CountryISO.SaudiArabia];
  contactUsForm: FormGroup = new FormGroup({
    phone: new FormControl(''),
    fullName: new FormControl(''),
    email: new FormControl(''),
    jobTitle: new FormControl(''),
    body: new FormControl(''),
    recaptcha: new FormControl(''),
  });

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.Egypt, CountryISO.SaudiArabia];
  }

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private fb: FormBuilder,
    private postForm: GetDataService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.contactUsForm = this.fb.group({
      fullName: new FormControl(null, [Validators.required, this.spacesOnlyValidator]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      jobTitle: new FormControl(''),
      body: new FormControl('', [Validators.required, this.spacesOnlyValidator]),
      recaptcha: new FormControl('', [Validators.required]),
    });

    //Send page title to google analytics
    gtag('config', 'G-YGS8HSR5WZ', {
      page_title: 'Contact Us',
      page_path: window.location.pathname,
    });
  }
  get fullName() {
    return this.contactUsForm.get('fullName')!;
  }

  get email() {
    return this.contactUsForm.get('email')!;
  }

  get phone() {
    return this.contactUsForm.get('phone')!;
  }

  get body() {
    return this.contactUsForm.get('body')!;
  }
  get recaptcha() {
    return this.contactUsForm.get('recaptcha')!;
  }

  public spacesOnlyValidator(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;

    if (/^\s+$/.test(value)) {
      return { spacesOnly: true };
    }

    return null;
  }

  onSubmit(data: any) {
    this.submitted = true;
    if (this.contactUsForm.valid) {
      var filteredData = this.filtterData(data, data.phone.internationalNumber);
      this.postForm
        .postData(`${environment.apiBaseUrl}/api/v1/contact`, filteredData)
        .subscribe(
          (res) => {
            // this.toastr.success(
            //   'Your message sent successfully',
            //   'Message',
            //   {}
            // );
            this.thanksMessage = `Thank you for reaching out! We'll get back to you soon.`;
            window.scrollTo(0, 300);
          },
          (error) => {
            console.log(error);
            // this.toastr.error('Your request failed', 'Message', {});
          }
        );
    } else {
     
      // this.toastr.error(
      //   'could not send due to missing required information',
      //   'Message',
      //   {}
      // );
    }
  }
  filtterData(array: any, phoneNum: string) {
    delete array.phone;
    var number: String = phoneNum;
    var x = number.replace(/\s/g, '');
    array.phone = x;
    return array;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadingComponent.hideLoading();
      const body = this.el.nativeElement.ownerDocument.body;
      this.renderer.removeClass(body, 'loading-overflow');
    }, 500);
  }
}
