<footer>
  <div class="overlay overlay-right"></div>
  <div class="overlay overlay-left"></div>
  <div class="container-lg">
    <div class="row">
      <div class="footer-left col-lg-3 col-md-7 col-8">
        <div class="img-holder">
          <img
            routerLink="home"
            src="../../../../assets/images/footer/footer_logo.webp"
            title="Qeema"
            alt="Qeema logo"
          />
        </div>
        <p>
          a leading ICT solutions provider, system integrator, and software
          house
        </p>
        <div class="social-holder">
          <span class="social-label">Follow us</span>
          <div class="social-links-holder">
            <a
              href="https://www.linkedin.com/company/qeema/mycompany/"
              target="_blank"
            >
              <img
                src="../../../../assets/images/footer/linkedin.webp"
                alt="LinkedIn logo"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-center col-lg-5 col-md-5 col-12">
        <div class="centeral-box">
          <h3 class="footer-title">Quick Links</h3>
          <div class="links-holder">
            <ul>
              <li routerLink="/home">Home</li>
              <li routerLink="/about">About Us</li>
            </ul>
            <ul>
              <li routerLink="/services">Services</li>
              <li routerLink="/products">Products</li>
            </ul>
            <ul>
              <li routerLink="/news">News</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-right col-lg-4 col-12">
        <div class="centeral-box">
          <h3 class="footer-title">Get In Touch</h3>
          <!-- <div class="tel-holder">
                        <div class="img-holder">
                            <img src="../../../../assets/images/footer/phone.svg" alt="Phone Icon">
                        </div>
                        <div class="tel-numbers">
                            <a class="numbers" href="tel:+966 25634623">+966 25634623</a>
                            <a class="numbers" href="tel:+02 2623586">+02 2623586</a>
                        </div>
                    </div> -->
          <div class="email-holder">
            <div class="img-holder">
              <img
                src="../../../../assets/images/footer/email.webp"
                alt="Email Icon"
              />
            </div>
            <div class="email-links">
              <a class="emails" href="mailto:info@qeema.net">info@qeema.net</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyrights-holder">
      <span class="copyrights"
        >© {{ currentYear }} Qeema. All Rights Reserved ​</span
      >
    </div>
  </div>
</footer>
