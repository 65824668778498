import { Component, Input, OnInit } from '@angular/core';
import { SectionsData } from '../../viewModels/sections-data';

@Component({
  selector: 'app-details-image-diagram',
  templateUrl: './details-image-diagram.component.html',
  styleUrls: ['./details-image-diagram.component.scss'],
})
export class DetailsImageDiagramComponent implements OnInit{
  @Input() imageDiagramSection: SectionsData | undefined;
  @Input() imageDiagramSection2: any | undefined;

  ngOnInit(): void {


  }
}
